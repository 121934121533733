import Link from 'next/link';

interface Props {
  width?: string;
  height?: string;
}

export const Logo: React.FC<Props> = () => {
  return (
    <Link href="/" style={{ textDecoration: 'none' }}>
      <svg
        width="175"
        height="30"
        viewBox="0 0 175 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M52.0688 4.4134H54.8684V24.5638H52.0688V15.2592H42.2849V24.5638H39.4854V4.4134H42.2849V12.8167H52.0688V4.4134Z"
          fill="white"
        />
        <path
          d="M57.622 17.0329C57.622 15.6953 57.8144 14.5322 58.1993 13.5436C58.5841 12.5356 59.094 11.7021 59.7289 11.043C60.3638 10.3839 61.095 9.88959 61.9223 9.56005C62.7497 9.23051 63.5963 9.06574 64.4621 9.06574C66.4824 9.06574 68.0313 9.70544 69.1088 10.9848C70.1863 12.2448 70.725 14.1736 70.725 16.7712C70.725 16.8875 70.725 17.0426 70.725 17.2364C70.725 17.4109 70.7154 17.5756 70.6961 17.7307H60.4216C60.537 19.3009 60.9892 20.493 61.778 21.3072C62.5669 22.1214 63.7983 22.5284 65.4723 22.5284C66.4151 22.5284 67.2039 22.4509 67.8389 22.2958C68.4931 22.1214 68.9837 21.9566 69.3108 21.8015L69.686 24.0695C69.3589 24.244 68.7817 24.4282 67.9543 24.622C67.1462 24.8158 66.2227 24.9128 65.1837 24.9128C63.8753 24.9128 62.7401 24.7189 61.778 24.3312C60.8352 23.9241 60.056 23.3717 59.4403 22.6738C58.8246 21.976 58.3628 21.1521 58.055 20.2023C57.7663 19.233 57.622 18.1766 57.622 17.0329ZM67.9255 15.5499C67.9447 14.3287 67.6369 13.3304 67.0019 12.555C66.3862 11.7602 65.53 11.3628 64.4333 11.3628C63.8176 11.3628 63.2692 11.4888 62.7882 11.7408C62.3264 11.9735 61.932 12.2836 61.6049 12.6713C61.2778 13.059 61.018 13.5048 60.8256 14.0089C60.6525 14.5129 60.537 15.0265 60.4793 15.5499H67.9255Z"
          fill="white"
        />
        <path
          d="M78.4577 22.6157C79.0926 22.6157 79.6506 22.606 80.1316 22.5866C80.6319 22.5478 81.0456 22.4897 81.3727 22.4121V17.9052C81.1803 17.8083 80.8628 17.7307 80.4202 17.6726C79.9969 17.595 79.4774 17.5563 78.8617 17.5563C78.4577 17.5563 78.0248 17.5853 77.563 17.6435C77.1205 17.7016 76.7068 17.8276 76.322 18.0215C75.9564 18.196 75.6485 18.448 75.3984 18.7775C75.1483 19.0877 75.0232 19.5044 75.0232 20.0278C75.0232 20.9971 75.3311 21.6755 75.9468 22.0632C76.5625 22.4315 77.3994 22.6157 78.4577 22.6157ZM78.2268 9.06574C79.3043 9.06574 80.2086 9.21112 80.9397 9.5019C81.6901 9.77328 82.2866 10.1707 82.7291 10.6941C83.1909 11.1981 83.518 11.8087 83.7104 12.5259C83.9028 13.2238 83.999 13.9992 83.999 14.8521V24.3022C83.7681 24.3409 83.441 24.3991 83.0177 24.4766C82.6137 24.5348 82.1519 24.5929 81.6324 24.6511C81.1129 24.7092 80.5453 24.7577 79.9296 24.7965C79.3331 24.8546 78.7367 24.8837 78.1402 24.8837C77.2936 24.8837 76.5144 24.7965 75.8025 24.622C75.0906 24.4475 74.4748 24.1761 73.9553 23.8078C73.4358 23.4201 73.0318 22.9161 72.7432 22.2958C72.4546 21.6755 72.3103 20.9292 72.3103 20.0569C72.3103 19.2233 72.4738 18.5061 72.8009 17.9052C73.1472 17.3043 73.609 16.8196 74.1862 16.4513C74.7635 16.083 75.4369 15.8116 76.2065 15.6372C76.9761 15.4627 77.7843 15.3755 78.6309 15.3755C78.9002 15.3755 79.1792 15.3949 79.4678 15.4336C79.7564 15.453 80.0258 15.4918 80.2759 15.5499C80.5453 15.5887 80.7762 15.6275 80.9686 15.6662C81.161 15.705 81.2957 15.7341 81.3727 15.7535V14.9975C81.3727 14.5516 81.3246 14.1155 81.2284 13.689C81.1322 13.2432 80.959 12.8555 80.7089 12.5259C80.4587 12.177 80.1124 11.9056 79.6699 11.7118C79.2466 11.4985 78.6886 11.3919 77.9959 11.3919C77.1108 11.3919 76.3316 11.4598 75.6582 11.5954C75.004 11.7118 74.5133 11.8378 74.1862 11.9735L73.8688 9.73451C74.2151 9.57944 74.7923 9.43405 75.6004 9.29836C76.4085 9.14328 77.284 9.06574 78.2268 9.06574Z"
          fill="white"
        />
        <path
          d="M91.7567 24.8546C90.102 24.8158 88.9284 24.4572 88.2357 23.7788C87.543 23.1003 87.1967 22.0438 87.1967 20.6094V2.46523L89.8808 2V20.1732C89.8808 20.619 89.9192 20.9874 89.9962 21.2781C90.0732 21.5689 90.1982 21.8015 90.3714 21.976C90.5446 22.1504 90.7755 22.2861 91.0641 22.3831C91.3527 22.4606 91.7086 22.5284 92.1319 22.5866L91.7567 24.8546Z"
          fill="white"
        />
        <path
          d="M96.4545 9.44374H102.14V11.7118H96.4545V18.6903C96.4545 19.4463 96.5122 20.0763 96.6277 20.5803C96.7431 21.0649 96.9163 21.4526 97.1472 21.7434C97.3781 22.0148 97.6667 22.2086 98.013 22.3249C98.3593 22.4412 98.7634 22.4994 99.2252 22.4994C100.033 22.4994 100.678 22.4121 101.159 22.2377C101.659 22.0438 102.005 21.9081 102.198 21.8306L102.717 24.0695C102.448 24.2052 101.977 24.37 101.303 24.5638C100.63 24.7771 99.8601 24.8837 98.9943 24.8837C97.9745 24.8837 97.1279 24.7577 96.4545 24.5057C95.8003 24.2343 95.2712 23.8369 94.8671 23.3135C94.4631 22.7901 94.1745 22.1504 94.0013 21.3944C93.8474 20.619 93.7704 19.7273 93.7704 18.7193V5.22756L96.4545 4.76233V9.44374Z"
          fill="white"
        />
        <path
          d="M104.353 24.5638V2.46523L107.037 2V9.73451C107.537 9.54067 108.067 9.39528 108.625 9.29836C109.202 9.18205 109.769 9.12389 110.327 9.12389C111.52 9.12389 112.511 9.29836 113.3 9.64728C114.089 9.97682 114.714 10.4517 115.176 11.0721C115.657 11.673 115.994 12.3999 116.186 13.2528C116.379 14.1058 116.475 15.0459 116.475 16.0733V24.5638H113.791V16.6549C113.791 15.7244 113.723 14.9296 113.589 14.2705C113.473 13.6115 113.271 13.0784 112.983 12.6713C112.694 12.2642 112.309 11.9735 111.828 11.799C111.347 11.6051 110.751 11.5082 110.039 11.5082C109.75 11.5082 109.452 11.5276 109.144 11.5664C108.836 11.6051 108.538 11.6536 108.249 11.7118C107.98 11.7505 107.73 11.799 107.499 11.8571C107.287 11.9153 107.133 11.9638 107.037 12.0025V24.5638H104.353Z"
          fill="white"
        />
        <path
          d="M126.936 22.5575C129.86 22.5575 131.323 21.5495 131.323 19.5335C131.323 18.9132 131.188 18.3898 130.919 17.9633C130.668 17.5175 130.322 17.1395 129.88 16.8293C129.437 16.4998 128.927 16.2187 128.35 15.9861C127.792 15.7535 127.195 15.5209 126.561 15.2882C125.829 15.0362 125.137 14.7552 124.482 14.445C123.828 14.1155 123.261 13.7375 122.78 13.311C122.299 12.8651 121.914 12.3418 121.625 11.7408C121.356 11.1399 121.221 10.413 121.221 9.56005C121.221 7.79604 121.818 6.41972 123.011 5.4311C124.204 4.44248 125.849 3.94817 127.946 3.94817C129.158 3.94817 130.255 4.08386 131.236 4.35525C132.237 4.60725 132.968 4.88833 133.429 5.19848L132.535 7.49558C132.131 7.24357 131.525 7.00126 130.717 6.76865C129.928 6.51665 129.004 6.39065 127.946 6.39065C127.407 6.39065 126.907 6.4488 126.445 6.56511C125.983 6.68142 125.579 6.85588 125.233 7.0885C124.887 7.32111 124.608 7.62158 124.396 7.98989C124.204 8.33881 124.107 8.75558 124.107 9.2402C124.107 9.78298 124.213 10.2385 124.425 10.6068C124.636 10.9751 124.935 11.3047 125.319 11.5954C125.704 11.8668 126.147 12.1188 126.647 12.3515C127.167 12.5841 127.734 12.8167 128.35 13.0493C129.216 13.3982 130.005 13.7472 130.717 14.0961C131.448 14.445 132.073 14.8618 132.592 15.3464C133.131 15.831 133.545 16.4126 133.834 17.091C134.122 17.7501 134.266 18.5546 134.266 19.5044C134.266 21.2684 133.622 22.6254 132.333 23.5752C131.063 24.5251 129.264 25 126.936 25C126.147 25 125.416 24.9418 124.742 24.8255C124.088 24.7286 123.501 24.6123 122.982 24.4766C122.462 24.3215 122.01 24.1665 121.625 24.0114C121.26 23.8369 120.971 23.6915 120.759 23.5752L121.596 21.2491C122.039 21.5011 122.712 21.7821 123.617 22.0923C124.521 22.4024 125.627 22.5575 126.936 22.5575Z"
          fill="#005050"
        />
        <path
          d="M142.797 24.9128C141.584 24.9128 140.517 24.7189 139.593 24.3312C138.689 23.9435 137.919 23.4008 137.284 22.7029C136.668 22.0051 136.207 21.1812 135.899 20.2314C135.591 19.2621 135.437 18.196 135.437 17.0329C135.437 15.8698 135.601 14.8036 135.928 13.8344C136.274 12.8651 136.755 12.0316 137.371 11.3338C137.986 10.6165 138.737 10.0641 139.622 9.67636C140.526 9.26928 141.527 9.06574 142.623 9.06574C143.297 9.06574 143.97 9.12389 144.644 9.2402C145.317 9.35651 145.962 9.54067 146.577 9.79267L145.971 12.0898C145.567 11.8959 145.096 11.7408 144.557 11.6245C144.038 11.5082 143.48 11.4501 142.883 11.4501C141.382 11.4501 140.228 11.925 139.42 12.8748C138.631 13.8247 138.237 15.2107 138.237 17.0329C138.237 17.847 138.323 18.5933 138.496 19.2718C138.689 19.9503 138.977 20.5318 139.362 21.0164C139.766 21.5011 140.276 21.8791 140.892 22.1504C141.507 22.4024 142.258 22.5284 143.143 22.5284C143.855 22.5284 144.499 22.4606 145.077 22.3249C145.654 22.1892 146.106 22.0438 146.433 21.8887L146.808 24.1568C146.654 24.2537 146.433 24.3506 146.145 24.4475C145.856 24.5251 145.529 24.5929 145.163 24.6511C144.798 24.7286 144.403 24.7868 143.98 24.8255C143.576 24.8837 143.181 24.9128 142.797 24.9128Z"
          fill="#005050"
        />
        <path
          d="M153.567 22.6157C154.202 22.6157 154.76 22.606 155.241 22.5866C155.741 22.5478 156.155 22.4897 156.482 22.4121V17.9052C156.29 17.8083 155.972 17.7307 155.53 17.6726C155.106 17.595 154.587 17.5563 153.971 17.5563C153.567 17.5563 153.134 17.5853 152.672 17.6435C152.23 17.7016 151.816 17.8276 151.431 18.0215C151.066 18.196 150.758 18.448 150.508 18.7775C150.258 19.0877 150.132 19.5044 150.132 20.0278C150.132 20.9971 150.44 21.6755 151.056 22.0632C151.672 22.4315 152.509 22.6157 153.567 22.6157ZM153.336 9.06574C154.414 9.06574 155.318 9.21112 156.049 9.5019C156.799 9.77328 157.396 10.1707 157.838 10.6941C158.3 11.1981 158.627 11.8087 158.82 12.5259C159.012 13.2238 159.108 13.9992 159.108 14.8521V24.3022C158.877 24.3409 158.55 24.3991 158.127 24.4766C157.723 24.5348 157.261 24.5929 156.742 24.6511C156.222 24.7092 155.655 24.7577 155.039 24.7965C154.442 24.8546 153.846 24.8837 153.249 24.8837C152.403 24.8837 151.624 24.7965 150.912 24.622C150.2 24.4475 149.584 24.1761 149.065 23.8078C148.545 23.4201 148.141 22.9161 147.852 22.2958C147.564 21.6755 147.42 20.9292 147.42 20.0569C147.42 19.2233 147.583 18.5061 147.91 17.9052C148.256 17.3043 148.718 16.8196 149.296 16.4513C149.873 16.083 150.546 15.8116 151.316 15.6372C152.085 15.4627 152.894 15.3755 153.74 15.3755C154.009 15.3755 154.288 15.3949 154.577 15.4336C154.866 15.453 155.135 15.4918 155.385 15.5499C155.655 15.5887 155.885 15.6275 156.078 15.6662C156.27 15.705 156.405 15.7341 156.482 15.7535V14.9975C156.482 14.5516 156.434 14.1155 156.338 13.689C156.241 13.2432 156.068 12.8555 155.818 12.5259C155.568 12.177 155.222 11.9056 154.779 11.7118C154.356 11.4985 153.798 11.3919 153.105 11.3919C152.22 11.3919 151.441 11.4598 150.767 11.5954C150.113 11.7118 149.623 11.8378 149.296 11.9735L148.978 9.73451C149.324 9.57944 149.902 9.43405 150.71 9.29836C151.518 9.14328 152.393 9.06574 153.336 9.06574Z"
          fill="#005050"
        />
        <path
          d="M162.364 9.8799C162.979 9.72482 163.797 9.56005 164.817 9.38559C165.837 9.21113 167.01 9.12389 168.338 9.12389C169.531 9.12389 170.522 9.29836 171.311 9.64728C172.099 9.97682 172.725 10.4517 173.187 11.0721C173.668 11.673 174.004 12.3999 174.197 13.2528C174.389 14.1058 174.485 15.0459 174.485 16.0733V24.5638H171.801V16.6549C171.801 15.7244 171.734 14.9296 171.599 14.2705C171.484 13.6115 171.282 13.0784 170.993 12.6713C170.705 12.2642 170.32 11.9735 169.839 11.799C169.358 11.6051 168.761 11.5082 168.049 11.5082C167.761 11.5082 167.462 11.5179 167.155 11.5373C166.847 11.5567 166.549 11.5858 166.26 11.6245C165.991 11.6439 165.74 11.673 165.51 11.7118C165.298 11.7505 165.144 11.7796 165.048 11.799V24.5638H162.364V9.8799Z"
          fill="#005050"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.61145 17.9797C10.1579 23.5666 16.5062 28.1592 16.5062 28.1592C16.5062 28.1592 23.3399 24.3261 28.498 19.2983L21.0404 18.8686C20.921 18.8618 20.8071 18.8177 20.7159 18.7432C20.6247 18.6687 20.5612 18.5677 20.535 18.4557L18.885 11.3909L14.2107 22.1492C14.0105 22.61 13.315 22.566 13.175 22.0837L11.0463 14.7495L9.56777 17.8712C9.52208 17.9677 9.44699 18.0485 9.35235 18.1031C9.25771 18.1577 9.14794 18.1835 9.03745 18.1771L5.61145 17.9797ZM4.74739 16.8752C2.53485 13.9258 0.946713 10.7731 1.11137 7.91511C1.36322 3.54374 5.3779 0.219536 10.0785 0.49035C13.7514 0.701959 16.7562 3.04593 17.7744 6.1462C19.1419 3.18338 22.3961 1.20001 26.069 1.41161C30.7696 1.68243 34.3759 5.44571 34.1241 9.81707C33.9594 12.6751 32.0197 15.6246 29.4832 18.3004L21.5155 17.8413L19.578 9.54499C19.4608 9.0432 18.7411 8.97978 18.5354 9.45322L13.8065 20.3373L11.6985 13.0746C11.5608 12.6003 10.8813 12.547 10.6697 12.9939L8.72282 17.1043L4.74739 16.8752Z"
          fill="#005757"
        />
      </svg>
    </Link>
  );
};
