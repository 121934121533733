'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import React, { Suspense, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '@/lib/client-utils';
import styles from '../styles/Home.module.css';
import { Logo } from '@/components/Logo';

function DemoMeetingTab(props: { label: string }) {
  const router = useRouter();
  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const startMeeting = () => {
    if (e2ee) {
      router.push(`/rooms/${generateRoomId()}#${encodePassphrase(sharedPassphrase)}`);
    } else {
      router.push(`/rooms/${generateRoomId()}`);
    }
  };
  return (
    <div className={styles.tabContent}>
      <p style={{ margin: 0, textAlign: 'center' }}>Entre para iniciar a sua consulta.</p>

      <div>
        <input
          id="use-e2ee"
          type="text"
          placeholder="Informe seu codigo de acesso"
          style={{
            width: '100%',
            padding: '1rem',
            margin: '1rem 0',
            borderRadius: '0.5rem',
            border: '1px solid #333',
          }}
        />
      </div>
      <button style={{ marginTop: '1rem' }} className="lk-button" onClick={startMeeting}>
        Entrar
      </button>
    </div>
  );
}

export default function Page() {
  return (
    <>
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Logo />
          </div>
          {/* <img src="/images/livekit-meet-home.svg" alt="LiveKit Meet" width="360" height="45" /> */}
          <h2>Conecte-se com seu médico de qualquer lugar.</h2>
        </div>
        <Suspense fallback="Loading">
          <DemoMeetingTab label="Consulta" />
        </Suspense>
      </main>
      <footer data-lk-theme="default" style={{ color: '#ccc', textAlign: 'center' }}>
        <span>Conectando pacientes e médicos para cuidados de saúde acessíveis e seguros.</span>
        <p>© 2024 Healthscan Telemedicina - Todos os direitos reservados.</p>
      </footer>
    </>
  );
}
